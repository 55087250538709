import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const Footer = ({ siteTitle }) => (
  <Fragment>
    <footer className="footer-3 text-center-xs space--xs ">
      <div className="container">
        <div className="row" />
        <div className="row">
          <div className="col-md-6">
            <p className="type--fine-print">
              Help us design the University that we are dreaming of.
            </p>
          </div>
          <div className="col-md-6 text-right text-center-xs">
            <span className="type--fine-print">
              &copy; <span className="update-year" /> UniverSIS
            </span>
          </div>
        </div>
      </div>
    </footer>
  </Fragment>
);

export default Footer;
