import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { CONTACT_TEXTS } from "../assets/data/languages";

const Contact = props => (
  <Fragment>
    <section className="text-center imagebg" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <div className="cta">
              <h2>{CONTACT_TEXTS.title.en}</h2>
              <h4>{CONTACT_TEXTS.subtitle.en}</h4>
              <h5>{CONTACT_TEXTS.subtitle2.en}</h5>
              <a
                className="btn btn--primary type--uppercase"
                href="mailto:info@universis.gr?Subject=Πληροφορίες για UniverSIS"
              >
                <span className="btn__text"> info@universis.gr </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

export default Contact;
