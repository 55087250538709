import PropTypes from "prop-types";
import React, { Fragment } from "react";

const Entities = () => (
  <Fragment>
    <section className="text-center bg--secondary" id="entities">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h2>Entities</h2>
            <p className="lead">
              The UniverSIS platform consists of the following:
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="bg--secondary">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="feature feature-4 boxed boxed--lg boxed--border">
              <i className="icon icon-Data-Network" />
              <h4>Core</h4>
              <hr />
              <p className="lead">
                The models defining the backend schema, providing database
                agnostic persistence (future development)
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature feature-4 boxed boxed--lg boxed--border">
              <i className="icon icon-Network-Window" />
              <h4><a href='https://gitlab.com/universis/universis-api'>API</a></h4>
              <hr />
              <p className="lead">
                The data backend methods to abstract the persistence layer
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature feature-4 boxed boxed--lg boxed--border">
              <i className="icon icon-Key-Lock" />
              <h4><a href='https://gitlab.com/universis/universis-auth'>OAUTH server</a></h4>
              <hr />
              <p className="lead">
                The authentication/authorization server to provide access to the
                API
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature feature-4 boxed boxed--lg boxed--border">
              <i className="icon icon-Student-MaleFemale" />
              <h4><a href='https://gitlab.com/universis/universis-students'>Students</a></h4>
              <hr />
              <p className="lead">
                The front-end interface for all student interaction (under
                testing)
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature feature-4 boxed boxed--lg boxed--border">
              <i className="icon icon-Professor" />
              <h4><a href='https://gitlab.com/universis/universis-teachers'>Teachers</a></h4>
              <hr />
              <p className="lead">
                The front-end interface for all teacher interaction (under
                development)
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature feature-4 boxed boxed--lg boxed--border">
              <i className="icon icon-Folder-Organizing" />
              <h4><a href='https://gitlab.com/universis/universis'>Registrar</a></h4>
              <hr />
              <p className="lead">
                The back-office application for all Registrar processes (future
                development)
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

Entities.propTypes = {
  siteTitle: PropTypes.string
};

export default Entities;
