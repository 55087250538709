import React, { Fragment, Component } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import Helmet from "react-helmet";
import Header from "../components/header";
import Entities from "../components/entities";
import Entities2 from "../components/entities2";
import Stack from "../components/stack";
import Trial from "../components/trial";
import Universities from "../components/universities";
import Contact from "../components/contact";
import Hero from "../components/hero";
import Footer from "../components/footer";

class IndexPage extends Component {
  render() {
    return (
      <Fragment>
        <SEO
          title="Home"
          keywords={[
            `universis`,
            `sis`,
            `student`,
            `information`,
            `open-source`
          ]}
        />
        <Header />
        <div className="main-container">
          <Hero />
          <Entities />
          <Stack />
          <Trial />
          <Contact />
          <Footer />
        </div>
      </Fragment>
    );
  }
}

export default IndexPage;
