import React, { Fragment } from "react";
import students from "../images/universis-students.png";
import browserstack from '../images/browserstack.png'

const Stack = props => (
  <Fragment>
    <section className="text-center" id="stack">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h2>Clone and start developing</h2>
            <p className="lead">
              Follow the commands bellow and get ready to join a team of
              ambitious developers. Check out our tech stack.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row align-items-center justify-content-around">
          <div className="col-md-12 col-xs-12 text-center">
            <h2>Hosted on Gitlab</h2>
            <img
              src="https://img.shields.io/badge/License-LGPLv3-blue.svg"
              alt=""
            />
          </div>
          <div className="col-sm-2 tech-stack">
            <a href='https://angular.io/'>
              <img
                alt="Image"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/1200px-Angular_full_color_logo.svg.png"
              />
            </a>


          </div>
          <div className="col-md-2 col-sm-2 tech-stack">
            <a href='https://github.com/twbs/bootstrap/'>
              <img
                src="https://camo.githubusercontent.com/a5f1061fb7cedc496c697e46448c39a1e6540184/68747470733a2f2f676574626f6f7473747261702e636f6d2f646f63732f342e332f6173736574732f6272616e642f626f6f7473747261702d736f6c69642e737667"
                alt=""
              />
            </a>

          </div>
          <div className="col-md-2 col-sm-2 tech-stack">
            <a href='https://github.com/expressjs/express'>
              <img
                  alt="Image"
                  src="https://upload.wikimedia.org/wikipedia/commons/6/64/Expressjs.png"
              />
            </a>

          </div>
          <div className="col-md-2 col-sm-2 tech-stack">
            <a href="https://github.com/themost-framework/themost">
              <p className="lead">@TheMost</p>
            </a>
          </div>
          <div className="col-md-2 col-sm-2 tech-stack">
            <a href='https://github.com/coreui'>
              <img
                  src="https://avatars3.githubusercontent.com/u/36859861?s=200&v=4"
                  alt=""
              />
            </a>
          </div>

          <div className="col-md-2 col-sm-2 tech-stack">
            <a href='https://browserstack.com'>
              <img src={ browserstack } alt='BrowserStack'/>
            </a>
            We use BrowserStack to test our web application in multiple platforms!
          </div>

          <div className="col-md-12 col-lg-12">
            <img alt="Image" src={students} />
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

export default Stack;
