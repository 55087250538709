import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import uniStudent from "../assets/images/drawkit-content-man-colour.svg";

const Hero = props => (
  <Fragment>
    <section>
      <div className="container">
        <div className="row align-items-center justify-content-around">
          <div className="col-md-6 col-lg-5">
            <h1 className="head-title">UniverSIS</h1>
            <h2>Redesigning our universities universe</h2>
            <p className="lead">
              <a target="_blank" rel="noopener noreferrer" href='https://universis.gr/' class='grey'>UniverSIS</a> is a coordinated effort by Greek academic institutions
              to build a Student Information System as an open source platform.
              The target is to serve our common needs to support academic and
              administrative processes.
            </p>
            <a className="btn btn--primary type--uppercase" href="#contact">
              <span className="btn__text">Learn More</span>
            </a>
          </div>
          <div className="col-md-6 col-sm-4 text-left">
            <img alt="Image" src={uniStudent} />
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

export default Hero;
