import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { TRIAL_TEXTS } from "../assets/data/languages";

const Trial = props => (
  <Fragment>
    <section className="text-center" id="demo">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h2>Demo</h2>
            <p className="lead">
              Check out our{" "}
              <a href="https://gitlab.com/universis/universis-students#demo">
                Students demo
              </a>
            </p>
            <p className="lead">
              Check out our{" "}
              <a href="https://gitlab.com/universis/universis-teachers#demo">
                Teachers demo
              </a>
            </p>
            <p className="lead">
              Check out our{" "}
              <a href="https://gitlab.com/universis/universis#demo">
                Registrar demo
              </a>
            </p>
            <a className="btn btn--primary type--uppercase " href="#contact">
              <span className="btn__text">Talk to us about your needs</span>
            </a>
          </div>
        </div>
      </div>

      <div className="container demonstration">
        <div className="row">
          <div className="col-md-7">
            <div className="feature feature-1">
              <img
                alt="Image"
                className="demoStudentImage"
                src="https://www.universis.gr/img/screenshots/students.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="text-center bg--secondary" id="participate">
      <div className="container" id='team'>
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h2>Contribute. Be part of the team</h2>
            <p className="lead">
              We know contributing to a big project is intimidating at first,
              but we have created the right environment to get you easily
              on-board.
              <br />
              <br />
              <strong>Are you a developer?</strong>
            </p>

            <a target='blank'
              className="btn btn--primary type--uppercase "
              href="https://gitlab.com/universis"
            >
              <span className="btn__text">Join us on Gitlab</span>
            </a>

            <a target='blank'
              className="btn btn--primary type--uppercase "
              href="https://discord.gg/rNupeHV"
            >
              <span className="btn__text">
                Join us on Discord
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

export default Trial;
